.tagify--outside {
    display: flex; /* Assurez-vous que tagify fonctionne bien en flexbox */
    /*flex-direction: column-reverse; !* Inverse les éléments : tags d'abord *!*/

    border: 0;
}




.tagify--outside .tagify__input {
    order: 0;
    flex: 100%;
    border: 1px solid var(--tags-border-color);
    margin-top: 1em; /* Donne un espace entre les tags et l'input */

    /*margin-bottom: 1em;*/

    transition: .1s;
}
/*.tagify__input {*/
/*    margin:0;*/
/*    line-height: 1.5;*/
/*}*/

/* Ajuster les tags en ligne */
.tagify--outside .tagify__tags {
    display: flex; /* Render les tags flexibles et alignés horizontalement */
    flex-wrap: wrap; /* Permet d'aller à la ligne si trop de tags */
    gap: 0.5em; /* Ajoute un espacement entre les tags */
}



.tagify--outside .tagify__input:hover {
    border-color: var(--tags-hover-border-color);
}

.tagify--outside.tagify--focus .tagify__input {
    transition: 0s;
    border-color: var(--tags-focus-border-color);
}

.tags-look .tagify__dropdown__item{
    display: inline-block;
    vertical-align: middle;
    border-radius: 3px;
    padding: .3em .5em;
    border: 1px solid #CCC;
    background: #F3F3F3;
    margin: .2em;
    font-size: .85em;
    color: black;
    transition: 0s;
}


.tagify__dropdown {
    position: absolute;
    z-index: 1055; /* Le z-index doit être supérieur au modal */
    /*top: 100%; !* Ajustez selon l'alignement voulu *!*/
    left: 0;
    width:300px;
    top: calc(100% + 2px); /* Ajustez cette valeur pour ajouter un léger décalage si nécessaire *

    width: 100%; !* Adapter le dropdown pour couvrir l'input *!*/
}

.tagify__custom-dropdown {
    position: absolute;
    z-index: 1055; /* Le z-index doit être supérieur au modal */
    /*top: 100%; !* Ajustez selon l'alignement voulu *!*/
    width:300px;
    left: 0;
    top: calc(100% + 2px); /* Ajustez cette valeur pour ajouter un léger décalage si nécessaire *

    width: 100%; /* Adapter le dropdown pour couvrir l'input */
}

.tags-look .tagify__dropdown__item{
    display: inline-block;
    vertical-align: middle;
    border-radius: 3px;
    padding: .3em .5em;
    border: 1px solid #CCC;
    background: #F3F3F3;
    margin: .2em;
    font-size: .85em;
    color: black;
    transition: 0s;
}

.tags-look .tagify__dropdown__item--active{
    border-color: black;
}

.tags-look .tagify__dropdown__item:hover{
    background: lightyellow;
    border-color: gold;
}

.tags-look .tagify__dropdown__item--hidden {
    max-width: 0;
    max-height: initial;
    padding: .3em 0;
    margin: .2em 0;
    white-space: nowrap;
    text-indent: -20px;
    border: 0;
}

.tagify__input{
    width:400px;
}
