// assets/styles/global.scss

// customize some Bootstrap variables
$body-bg:white;
$navbar-dark-color:rgba(#ffffff, .75);
$navbar-dark-hover-color:rgba(#ffffff, .95);
$pagination-hover-color:white;
$pagination-hover-bg:#DC3545;

$success: #18bc9c;
$primary:#0c63e4;
$info:#e2017b;
$dark:#2c3e50;
$secondary: #ff8800;


$table-hover-color: #ffffff;
$table-hover-bg-factor: .075;
$table-hover-bg: #0c63e4;


//Roboto Condensed
@font-face {
  font-family: 'Roboto Condensed';
  src: url('../fonts/Roboto/Roboto-Condensed.ttf') format("trueType")
}
.Roboto{
  font-family: "Roboto Condensed", sans-serif;
}

//Regular Brush
@font-face {
  font-family: 'Regular_brush';
  src: url("../fonts/Regular_brush/Regular Brush.otf") format("openType")
}
.Regular-brush{
  font-family: "Regular_brush", sans-serif;
}

// the ~ allows you to reference things in node_modules
@import "~bootstrap/scss/bootstrap";
@import './scss/_functions.scss';
@import './scss/_variables.scss';
@import './scss/_card.scss';
@import './scss/_shapes.scss';
@import './scss/_lift.scss';



//container app (simulation application)
.container-app{
  max-width: 500px;
  margin:auto;
}
@media (max-width: 576px) {
  .container-app{
    max-width:100%;
  }
}

//horizontal scrolling
.scrolling-wrapper {
  //cursor:grab;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  text-align:center;
}


.external-dropdown {
  position: relative;


}
.external-dropdown .dropdown, .external-dropdown .dropup {
  position: static;

}
.outer {
  overflow-x: auto;
}

.para {
  width: 80%;
  margin: auto;
}


/*tags*/
.lst-tag::before {
  width: 11px;
  height: 11px;
  display: inline-block;
  content: '';
  -webkit-mask: url('../svg/tag.svg') no-repeat 50% 50%;
  mask: url('../svg/tag.svg') no-repeat 50% 50%;
  -webkit-mask-size: cover;
  mask-size: cover;
  background-color: #e2017b;;
  margin-right: 2px;
}

.lst-tag, .lst-tag a{
  background-color: #f8f9fa;
  color:$dark;
  font-size:0.75em;
  font-weight:700;
  display:inline-block;
  padding-left: .65em;
  padding-right: .65em;
  padding-bottom: .15em;
  border-radius: 50rem;
  cursor:pointer;
}

.lst-tag:hover,.lst-tag.active{
  background-color: #e2017b;;
  color: #fff;
}
.lst-tag:hover:before,.lst-tag.active:before{
  background-color:  #fff;
}


//font awesome duo-icons

.fa-lst-info{
  --fa-secondary-opacity:1.0;
  --fa-secondary-color:white;;
  --fa-primary-color:#e2017b;
}

.fa-lst-info:hover{
  --fa-secondary-opacity:1.0;
  --fa-secondary-color: #e2017b;
  --fa-primary-color: white;
}

.fa-lst-alt-info{
  --fa-secondary-opacity:1.0;
  --fa-secondary-color: #e2017b;
  --fa-primary-color: white;
}
.fa-lst-alt-info:hover{
  --fa-secondary-opacity:1.0;
  --fa-secondary-color:white;
  --fa-primary-color: #e2017b;
}

.fa-lst-white-info{
  --fa-secondary-opacity:1.0;
  --fa-secondary-color: white;
  --fa-primary-color: white;
}
.fa-lst-white-info:hover{
  --fa-secondary-opacity:1.0;
  --fa-secondary-color:#e2017b;
  --fa-primary-color: #e2017b;
}

.fa-lst-info.active,.fa-lst-alt-info.active,.fa-lst-white-info.active{
  --fa-secondary-color:#e2017b;
  --fa-primary-color: #e2017b;
}

.fa-lst-success:hover{
  --fa-secondary-opacity:1.0;
  --fa-secondary-color: #18bc9c;
  --fa-primary-color: white;
}
.fa-lst-success{
  --fa-secondary-opacity:1.0;
  --fa-secondary-color:white;;
  --fa-primary-color:#18bc9c;
}

.fa-lst-alt-success:hover{
  --fa-secondary-opacity:1.0;
  --fa-secondary-color: #18bc9c;
  --fa-primary-color: white;
}
.fa-lst-alt-success{
  --fa-secondary-opacity:1.0;
  --fa-secondary-color:white;
  --fa-primary-color: #18bc9c;
}

.fa-lst-white-success{
  --fa-secondary-opacity:1.0;
  --fa-secondary-color: white;
  --fa-primary-color: white;
}
.fa-lst-white-success:hover{
  --fa-secondary-opacity:1.0;
  --fa-secondary-color:#18bc9c;
  --fa-primary-color: #18bc9c;
}

.fa-lst-success.active,.fa-lst-alt-success.active,.fa-lst-white-success.active{
  --fa-secondary-color:#18bc9c;
  --fa-primary-color: #18bc9c;
}

.fa-lst-danger:hover{
  --fa-secondary-opacity:1.0;
  --fa-secondary-color: #DC3545;
  --fa-primary-color: white;
}
.fa-lst-danger{
  --fa-secondary-opacity:1.0;
  --fa-secondary-color:white;;
  --fa-primary-color:#DC3545;
}

.fa-lst-alt-danger{
  --fa-secondary-opacity:1.0;
  --fa-secondary-color: #DC3545;
  --fa-primary-color: white;
}
.fa-lst-alt-danger:hover{
  --fa-secondary-opacity:1.0;
  --fa-secondary-color:white;
  --fa-primary-color: #DC3545;
}

.fa-lst-white-danger{
  --fa-secondary-opacity:1.0;
  --fa-secondary-color: white;
  --fa-primary-color: white;
}
.fa-lst-white-danger:hover{
  --fa-secondary-opacity:1.0;
  --fa-secondary-color:#DC3545;
  --fa-primary-color: #DC3545;
}

.fa-lst-danger.active,.fa-lst-alt-danger.active,.fa-lst-white-danger.active{
  --fa-secondary-color:#DC3545;
  --fa-primary-color: #DC3545;
}

.fa-lst-secondary:hover{
  --fa-secondary-opacity:1.0;
  --fa-secondary-color: #FFA500;;
  --fa-primary-color: white;
}
.fa-lst-secondary{
  --fa-secondary-opacity:1.0;
  --fa-secondary-color:white;;
  --fa-primary-color:#FFA500;;
}

.fa-lst-alt-secondary{
  --fa-secondary-opacity:1.0;
  --fa-secondary-color: #FFA500;
  --fa-primary-color: white;
}
.fa-lst-alt-secondary:hover{
  --fa-secondary-opacity:1.0;
  --fa-secondary-color:white;
  --fa-primary-color: #FFA500;
}

.fa-lst-white-secondary{
  --fa-secondary-opacity:1.0;
  --fa-secondary-color: white;
  --fa-primary-color: white;
}
.fa-lst-white-secondary:hover{
  --fa-secondary-opacity:1.0;
  --fa-secondary-color:#FFA500;;
  --fa-primary-color: #FFA500;;
}

.fa-lst-secondary.active,.fa-lst-alt-secondary.active,.fa-lst-white-secondary.active{
  --fa-secondary-color:#FFA500;;
  --fa-primary-color: #FFA500;;
}


  //font sizes
.fs-0{
  font-size:2.5rem
}
.fs-00{
  font-size:1rem !important
}

.fs-1{
  font-size: calc(1.27813rem + .3375vw)!important;
}
.fs-2{
  font-size:1.3rem !important
}

.fs-3{
  font-size: 1.15rem !important
}
.fs-4{
  font-size: 1rem !important;
}
.fs-5{
  font-size: 0.75rem !important;
}
.fs-5-alt, .fs-5-alt2{
  font-size: 0.7rem !important;
  line-height:1.2rem;
}

.fs-6{
  font-size: 0.6rem !important;
}

@media (max-width: 576px) {
  .fs-0{
    font-size:1.5rem
  }

  .fs-1{
    font-size: calc(1.17813rem + .3375vw)!important;
  }

  .fs-2{
    font-size: 1.1rem !important;
  }
  .fs-4{
    font-size: 0.85rem !important;
  }

  .fs-5-alt {
    font-size: 0.6rem !important;
  }

  .fs-5-alt2 {
    font-size: 0.55rem !important;
  }
}

// flashy
.flashy200{
   width:200px;
   margin-top:10px;
   padding-bottom:10px;
 }

.flashy300{
  width:400px;
  margin-top:10px;
  padding-bottom:15px;
}

@media (max-width: 576px) {
  .flashy200, .flashy300{
    margin-top:0;
    padding-bottom:18px;
    width:100%
  }

}




// navbar

.navbar-height{
  height:4.4rem;
}

.navbar-margin{
  height:4.6rem;
}

.navbar-padding{
  padding-top: 4em;
}

.navbar-link a{
  text-decoration:none;
  color:white;
}

.navbar-link-tag a{
  text-decoration:none;
  color:$dark;
}

.navbar-link .dropdown-item {
  color:$dark;
}

.navbar-link-success:hover{
  color:$success;
}

.navbar-link-primary:hover{
  color:$primary;
  text-decoration: underline;
}

.navbar-link-danger:hover{
  color:$danger;
}
.navbar-link-info:hover{
  color:$info;
}


.dropdown-toggle[aria-expanded="true"] {
  color:$info;
}
//.active-menu {
//  color:$success;
//}

.navbar-aux, .pill-aux{
  display: inline-block;
  padding: 0.25rem 0.75rem;
  font-size: 0.8em;
  font-weight: 600;
  line-height: 1.55;
  //background-color:#335EEA19;
  color: #2c3e50;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 50rem !important;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  text-decoration:none;
  cursor:pointer;
}

.navbar-aux-xs {
  display: inline-block;
  padding: 0.15rem 0.55rem;
  margin: 4px;
  font-size: 0.65em;
  font-weight: 600;
  line-height: 1.45;
  //background-color:#335EEA19;
  color: #2c3e50;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 5px !important;
  letter-spacing: 0.04em;
  text-decoration:none;
  cursor:pointer;
}



.navbar-aux-alt, .pill-aux-alt{
  display: inline-block;
  padding: 0.25rem 0.75rem;
  font-size: 0.75em;
  font-weight: 600;
  line-height: 1.55;
  background: $info;
  color:white;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 50rem !important;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  text-decoration:none;
  cursor:pointer;
}


.navbar-aux-success:hover,.navbar-aux-success:focus,.navbar-aux-success.active, {
  background: $success;
  color:white;
}
.navbar-aux-primary:hover,.navbar-aux-primary:focus,.navbar-aux-primary.active, {
  background: $primary;
  color:white;
}
.navbar-aux-danger:hover,.navbar-aux-danger:focus,.navbar-aux-danger.active, {
  background: $danger;
  color:white;
}
.navbar-aux-info:hover,.navbar-aux-info:focus,.navbar-aux-info.active, {
  background: $info;
  color:white;
}

.navbar-aux-alt-info:hover,.navbar-aux-alt-info:focus,.navbar-aux-alt-info.active, {
  background-color:#335EEA19;
  color: #2c3e50;
}

.navbar-aux-secondary:hover,.navbar-aux-secondary:focus,.navbar-aux-secondary.active, {
  background: $secondary;
  color:white;
}


@media (max-width: 576px) {
  .navbar-height{
    height:3rem;
  }

  .navbar-padding{
    padding-top:3em;
  }

  .navbar-margin{
    //height:3.3rem;
    height:2rem;
  }

}

// réglages hauteurs

.photo-height{
height: 100px;
}

.photo-modal-height{
  height:300px;
}
.photo-modal-width{
  width:90%;
}

.photo-modal-width-alt {
  width:200px;
}

.photo-modal-height-alt {
  height:200px;
}


@media (max-width: 992px) {
  .photo-modal-width{
    width:200px;
  }

  .photo-modal-height{
    height:200px;
  }



}

@media (max-width: 768px) {
  .logo-height{
    height:2.5rem;
  }


  .nav-regul{
    height:6.8rem;
  }
}

@media (max-width: 576px) {

  .photo-height {
    height: 78px;
  }

  .logo-height{
    height:1.8rem;
  }
  .nav-regul{
    height:6.8rem;
  }
}




//divers

.nodecoration{
  text-decoration: none;
}

// utilisé dans ticket_index
.dropdown-toggle[aria-expanded="true"]{
  color:white;
}


//utilisé dans radio_index
.radio-width{
  width: 200px;
}

@media (max-width: 992px) {
  .radio-width{
    width: 170px;
  }

}

@media (max-width: 576px) {
  .radio-width{
    width: 120px;
  }

}



// utilisée dans auteurs.index (nom sous la photo)
.photo-link-user{
  background-color: #646464;
  color:#fff;
  text-decoration: none;
}

.photo-link-adm{
  background-color: #DC3545;
  color:#fff;
  text-decoration: none;
}

.photo-link-adm:hover{
  background-color: $success;
  color:#fff;
}



input[name*="enedis"].form-check-input:checked {
  background-color:$secondary;
  border-color:$secondary;
}

.form-control-custom {
  display: block;
  width: 100%;
  padding: 0.05rem 0.15rem;
  font-size: 0.7rem;
  font-weight: 400;
  //line-height: 1.5;
  color: #212529;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}


label.required:after {
  content: "*";
  color: red;
}

p {
  margin-bottom :0;
}


a:active {outline-style:none;}

.form-check-label{
  padding-right: 1em;

}

.text-justify{
  text-align: justify;
}
.card-bordure {
  border: 1px solid $dark;
}

.card-width {
  width:25rem;
}

.fw-plus{
  font-weight: 600;
}

.pointer:hover{
    cursor:pointer;
}



.bg-green{
  background-color: #164d42;
}

.bg-orange{
  background-color: #d5743d;
}

.bg-lightprimary-hover:hover,.bg-lightblue-hover:hover{
  background-color: #e7f1ff;
}
.text-lightprimary {
  color: #e7f1ff;
}

.bg-lightprimary,.bg-lightblue{
  background-color: #e7f1ff;
}

.bg-lightsuccess,.bg-lightgreen{
  background-color: rgba(24, 188, 156, 0.2);
}
.text-lightsuccess {
  color: rgba(24, 188, 156, 0.2);
}


.bg-lightdanger,.bg-lightred{
  background-color: rgba(255, 0, 0, 0.18);
}
.text-lightdanger{
  color:rgba(255, 0, 0, 0.18);
}
.bg-lightinfo{
  background-color: rgba(226, 1, 123, 0.16)
}

.text-lightinfo {
  color: rgba(226, 1, 123, 0.16)
}


.bg-lightsecondary{
  background-color: #ffebca
}

.text-lightsecondary {
  color: #ffebca;
}


.bg-lightdark{
  background-color: rgba(44, 62, 80, 0.13);
}
.text-lightdark{
  color: rgba(44, 62, 80, 0.13);
}


#sortable li {
  list-style: none;
  cursor: pointer;
}

input[name*="exercice"].form-check-input:checked {
  background-color:$success;
  border-color:$success;
}


@media (max-width: 576px) {
  .card-width {
    width:21rem;
  }
}


//fieldset
fieldset {
  min-height:30px;
  position: relative;
  display: block;
  margin-top: 1em;
  margin-bottom: 2em;

  padding: 1.25em 1em 0.75em 1em;
  border:1px solid $dark;
  border-radius:8px;
}

 fieldset > legend {
  position: absolute;
   background:white;
   padding-left: 0.5rem;
   padding-right: 0.5rem;
  left:1em;
  top: -0.9em;
   color:$primary;
  width: auto;
}


//btn-xs

.btn-xs  {
  padding: 0.2rem 0.4rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.btn-xs.active  {
 color: white;
  background-color: $primary;
}

@media (max-width: 576px) {

  .btn-xs  {
    padding: 0.1rem 0.25rem;
    font-size: 0.87rem;
    border-radius: 0.2rem;
  }


}



//btn-icon
.btn-icon,  .btn-icon a, .btn-icon-expanded,.btn-icon-expanded a {
  font-weight: 400;
  position: relative;
  font-family: "Roboto Condensed",sans-serif;
  text-decoration: none;
  background-color: #fff;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid #2c3e50;
  padding: 0.275rem 0.45rem;
  font-size:1.25em;
  min-width: 10rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.btn-icon-reduced, .btn-icon-reduced a {

  min-width: 4.5rem;
  border: 1px solid #2c3e50;
  padding: 0 0.45rem;
  font-size:1.75em;
  background-color: #fff;
  border-radius: 0.25rem;
}
.btn-icon-reduced + div {
  display:inline-block;
  font-size:0.65em;
}

.btn-icon-reduced > span {
  display:none;
}


.btn-icon-primary{
  color: $primary;
  border-color: $primary;
}
.btn-icon-primary:hover {
  background-color: $primary;
  color:#FFFFFF ;
}

.btn-icon-info{
  color: $info;
  border-color: $info;
}
.btn-icon-info:hover {
  background-color: $info;
  border-color: $info;
  color:#FFFFFF ;
}

.btn-icon-success{
  color: $success;
  border-color: $success;
}
.btn-icon-success:hover {
  background-color: $success;
  color:#FFFFFF ;
}

.btn-icon-danger{
  color: $danger;
  border-color: $danger;
}
.btn-icon-danger:hover {
  background-color: $danger;
  color:#FFFFFF ;
}

.btn-icon-secondary{
  color: $secondary;
  border-color: $secondary;
}
.btn-icon-secondary:hover {
  background-color: $secondary;
  color:#FFFFFF ;
}

.btn-icon-dark{
  color: $dark;
  border-color: $dark;
}
.btn-icon-dark:hover {
  background-color: $dark;
  color:#FFFFFF ;
}

.btn-icon-danger-alt{
  color: white;
  border-color: $danger;
  background-color: $danger;
}
.btn-icon-danger-alt:hover {
  color: $danger;
  background-color: white;
}

.btn-icon-info-alt{
  color: white;
  border-color: $info;
  background-color: $info;
}
.btn-icon-info-alt:hover {
  color: $info;
  background-color: white;
}

.btn-icon-primary-alt{
  color: white;
  border-color: $primary;
  background-color: $primary;
}
.btn-icon-primary-alt:hover {
  color: $primary;
  background-color: white;
}

.btn-icon-success-alt{
  color: white;
  border-color: $success;
  background-color: $success;
}
.btn-icon-success-alt:hover {
  color: $success;
  background-color: white;
}


.btn-icon > span, .btn-icon-expanded > span, {
  color:#2c3e50;
  font-size:0.75em;
  margin-left: 0.25em;
}

.btn-icon-danger-alt > span,.btn-icon-info-alt > span,.btn-icon-primary-alt > span,.btn-icon-success-alt > span {
  color:white;
}

.btn-icon + div, .btn-icon-expanded + div, {
  display:none;
}

.btn-icon:hover > span, .btn-icon-expanded:hover > span {
  color: #FFFFFF;
}

.btn-icon-danger-alt:hover > span{
  color: $danger;
}
.btn-icon-info-alt:hover > span{
  color: $info;
}
.btn-icon-success-alt:hover > span{
  color: $success;
}
.btn-icon-primary-alt:hover > span{
  color: $primary;
}

@media (max-width: 768px) {

  .btn-icon, .btn-icon a {
    min-width: 4.5rem;
    padding: 0 0.45rem;
    font-size:1.75em;
    border-radius: 0.25rem;
  }
  .btn-icon + div {
    display:inline-block;
    font-size:0.65em;
  }

  .btn-icon > span {
    display:none;
  }

}

// card-icon
.card-icon, .card-icon a{
  display: inline-block;
  position: relative;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #2c3e50;
  padding: 0.275rem ;
  margin-bottom: 2rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.card-icon, .card-icon a {
  width: 165px;
  height:110px;
  font-size: 45px;
}

.card-pilote, .card-pilote a {
  text-decoration: none;
}

.card-pilote:hover, .card-pilote:hover a {
  color: $danger;
}

.card-prog, .card-prog a {
  max-width: 250px;
  height:90px;
  font-size: 1.4em;
  color: $info;
  line-height: 25px;
}


.card-icon > .titre{
   font-size: 20pt;
   color: #2c3e50;
 }

.card-icon > .sous-titre{
  font-size: 14pt;
  color: #2c3e50;
}

.card-icon > .icon{
  font-size: 26px;
}

.card-icon-sm{
  width: 96px;
  height:60px;
  font-size: 22px;
}
.card-icon-sm > .titre{
  font-size: 12pt;
  color: #2c3e50;
}

.card-icon-sm > .sous-titre{
  font-size: 8pt;
  color: #2c3e50;
}

.card-icon-sm > .icon-sm{
  font-size: 12px;

}


  .card-icon:hover, .card-icon a:hover {
    background: $primary;
    color: #FFFFFF;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.175) !important;
  }

  .card-prog:hover, .card-prog a:hover {
    background: $info;
    color: #FFFFFF;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.175) !important;
  }

  .card-icon:hover > .titre, .card-icon:hover > .sous-titre{
    color: #FFFFFF;
  }

  .card-icon-success{
    color: $success;
  }

  .card-icon-success:hover, .card-icon-success a:hover{
    background: $success;
  }

  .card-icon-danger{
    color: $danger;
  }

  .card-icon-danger:hover, .card-icon-danger a:hover{
    background: $danger;
  }

  .card-icon-dark{
    color: $dark;
  }

  .card-icon-dark:hover, .card-icon-dark a:hover{
    background: $dark;
  }

  .card-icon-primary{
    color: $primary;
  }

  .card-icon-primary:hover, .card-icon-primary a:hover{
    background: $primary;
  }

  .card-icon-secondary{
    color: $secondary;
  }

  .card-icon-secondary:hover, .card-icon-secondary a:hover {
    background: $secondary;
  }

  .card-icon-info {
    color: $info;
  }

  .card-icon-info:hover, .card-icon-info a:hover{
    background: $info;
  }


  @media (max-width: 1400px) {
    .card-icon, .card-icon a, .card-icon-alt {
      width: 150px;
      height: 100px;
      font-size: 43px
    }
    .card-icon > .titre{
      font-size: 18pt;
      color: #2c3e50;
    }

    .card-icon > .sous-titre{
      font-size: 12pt;
      color: #2c3e50;
    }

    .card-icon > .icon{
      font-size: 26px;
    }



  }

  @media (max-width: 1200px) {
    .card-icon, .card-icon a, .card-icon-alt {
      width: 120px;
      height: 80px;
      font-size: 33px

    }
    .card-icon > .titre{
      font-size: 14pt;
      color: #2c3e50;
    }

    .card-icon > .sous-titre{
      font-size: 10pt;
      color: #2c3e50;
    }

    .card-icon > .icon{
      font-size: 20px;
    }
  }

  @media (max-width: 992px) {
    .card-icon, .card-icon a, .card-icon-alt {
      width: 150px;
      height: 100px;
      font-size: 40px

    }
    .card-icon > .titre{
      font-size: 20pt;
      color: #2c3e50;
    }

    .card-icon > .sous-titre{
      font-size: 14pt;
      color: #2c3e50;
    }

    .card-icon > .icon{
      font-size: 20px;
    }


  }

  @media (max-width: 768px) {
    .card-icon, .card-icon a, .card-icon-alt {
      width: 120px;
      height: 80px;
      font-size: 32px

    }
    .card-icon > .titre{
      font-size: 16pt;
      color: #2c3e50;
    }

    .card-icon > .sous-titre{
      font-size: 12pt;
      color: #2c3e50;
    }

    .card-icon > .icon{
      font-size: 15px;
    }
  }

  @media (max-width: 576px) {

    .card-icon, .card-icon a, {
      width: 105px;
      height: 70px;
      font-size: 28px;
    }



    .card-icon > .titre{
      font-size: 13pt;
      color: #2c3e50;
    }

    .card-icon > .sous-titre{
      font-size: 10pt;
      color: #2c3e50;
    }

    .card-icon > .icon{
      font-size: 14px;
    }

  }


  /*modal*/

  .modal-footer {
    border-top: none;
  }

  .modal-content {
    border: none;
    border-radius: 0;
  }


  /*tables*/
  .table th:first-child,
  .table td:first-child {
    position: sticky;
    left: 0;
    background: white;

  }

  //.table > :not(caption) > * > * {
  //  padding: 0.1rem 0.1rem;
  //}

  //
  //.table-striped > tbody > tr > td.js-modal{
  //  color:$primary;
  //}
  //
  //.table-striped > tbody > tr:hover > td.js-modal{
  //  color:$white;
  //}
  //
  //.table-striped > tbody > tr > td.js-message{
  //  color:$primary;
  //}
  //
  //.table-striped > tbody > tr:hover > td.js-message{
  //  color:$white;
  //}
  //
  //
  //.table-striped > tbody > tr:hover > td > form > button.btn-link{
  //  color:$white;
  //}
  //
  //.table-hover tr:hover td a{
  //  color: white;
  //}
  //
  //
  //#table thead{
  //  position:sticky;
  //  background: white;
  //  top:0;
  //  z-index:1;
  //}
  //
  //#table th{
  //padding-top: .75em;
  //}
  //
  //.app-table{
  //  height:500px;
  //}


  /*lien sur javascript et sur les listes filtres sans javascript*/
  .link-js, .link-js2, .link-js3, .link-filter {
    cursor: pointer;
    /*color: #117c67;*/
    color: $dark;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 12pt;
    font-weight: 500;
  }

  .link-js-active, .link-js2-active, .link-js3-active.link-filter-active {
    cursor: pointer;
    /*color: #117c67;*/
    color: $primary;
    text-decoration: underline;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 12pt;
    font-weight: 500;
  }


  .link-js:hover, .link-js2:hover,.link-js3:hover, .link-filter:hover {
  color: $primary;
  text-decoration: underline;
  font-family: "Roboto Condensed", sans-serif;
}


  /*forms*/

  .form-label {
    margin-bottom: 0;
    text-transform: uppercase;
    font-size: small;
    font-weight: bold;
  }

  /*ckeditor5*/
  #editor, #editor2 {
    border: 1px solid lightgrey;
    border-radius: 8px;
  }

  .editor-100 {
    height: 100px;
    overflow: auto;
  }

  .editor-150 {
    height: 150px;
    overflow: auto;
  }

  .editor-200 {
    height: 200px;
    overflow: auto;
  }

  /*bouton sauvegarde*/
  .btn-fa-save::before {
    width: 12px;
    height: 12px;
    display: inline-block;
    content: '';
    -webkit-mask: url('../svg/floppy-disk.svg') no-repeat 50% 50%;
    mask: url('../svg/floppy-disk.svg') no-repeat 50% 50%;
    -webkit-mask-size: cover;
    mask-size: cover;
    background-color: white;
    margin-right: 5px;
  }


  .icon::before {
    display: inline-block;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
  }


  /* taille des blocs jingles */
  .sm-flat {
    width: 25%
  }

  @media (max-width: 1200px) {
    .sm-flat {
      width: 35%
    }
  }
  @media (max-width: 992px) {
    .sm-flat {
      width: 45%
    }
  }

  @media (max-width: 768px) {
    .sm-flat {
      width: 90%
    }
  }

  @media (max-width: 576px) {
    .sm-flat {
      width: 90%
    }
  }


  .icon::before {
    display: inline-block;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
  }


  /* taille des blocs jingles */
  .sm-flat {
    width: 25%
  }

  @media (max-width: 1200px) {
    .sm-flat {
      width: 35%
    }
  }
  @media (max-width: 992px) {
    .sm-flat {
      width: 45%
    }
  }

  @media (max-width: 768px) {
    .sm-flat {
      width: 90%
    }
  }

  @media (max-width: 576px) {
    .sm-flat {
      width: 90%
    }
  }


