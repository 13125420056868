
#pilotage{
    color:#18bc9c;
    cursor:pointer;
}

#pilotage.active{
    color:#DC3545;
}

.js-show-adm-icon, .js-show-adm-card, .js-show-adm-tag {
    display: none;
}

.js-show-adm-icon.active,.js-show-adm-card.active,.js-show-adm-tag  {
    display: inline-block;
}

