


.jconfirm-bg{

}
.jconfirm-box{
margin-bottom: 400px;

}
.jconfirm.jconfirm-modern .jconfirm-box.loading{

}
.jconfirm.jconfirm-modern .jconfirm-box.loading:before{
}
.jconfirm.jconfirm-modern .jconfirm-box.loading:after{
}
.jconfirm.jconfirm-modern .jconfirm-box div.jconfirm-closeIcon{


}
.jconfirm.jconfirm-modern .jconfirm-box .jconfirm-title-c{
}
.jconfirm.jconfirm-modern .jconfirm-box .jconfirm-icon-c{

}



.jconfirm.jconfirm-modern .jconfirm-box .jconfirm-content-pane{
}
.jconfirm.jconfirm-modern .jconfirm-box div.jconfirm-content{
    color:black;
    font-size:18px;

}
.jconfirm.jconfirm-modern .jconfirm-box .jconfirm-buttons{
}
.jconfirm.jconfirm-modern .jconfirm-box .jconfirm-buttons button{
}


@media (max-width: 576px) {
    .jconfirm-box{
        margin-bottom: 0;
    }

}

